import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { login } from "../../redux/auth/authSlice";

import { getUserCredentials } from "../../utils/helpers";

import Loading from "../../components/Blockers/Loading";

import urls from "../../utils/urls.json";

export default function RedirectIfAuth({ children }) {
  // Redirect to home page, if the user is authenticated
  const [loading, setLoading] = useState(true);

  const auth = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    // get credentials from local storage
    const credentials = getUserCredentials();
    if (
      credentials.accessToken &&
      credentials.refreshToken &&
      credentials.user
    ) {
      dispatch(login(credentials));
      navigate(urls.home.path);
    }
    setLoading(false);
  }, [dispatch, navigate]);

  return (
    <Loading loading={loading}>
      {!auth.isAuthenticated ? children : null}
    </Loading>
  );
}
