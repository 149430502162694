import { EditOutlined, VisibilityOutlined } from "@mui/icons-material";
import {
  Divider,
  IconButton,
  Paper,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import axios from "axios";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loading from "../../../components/Blockers/Loading";
import { DOMAIN } from "../../../utils/config";
import { getAuthorization } from "../../../utils/helpers";
import EditTrial from "./EditTrial";

function StyledPaper(props) {
  const { palette } = useTheme();

  return (
    <Paper
      sx={{
        height: "100%",
        padding: "12px",
        backgroundColor: palette.neutral[100],
        border: "1px solid #E8E8E8",
        borderRadius: "6px",
      }}
      elevation={0}
    >
      {props.children}
    </Paper>
  );
}

export default function TrialInfo() {
  const { palette } = useTheme();

  const [trial, setTrial] = useState([]);
  const [sharedWith] = useState([]);
  const [loading, setLoading] = useState(false);

  const { id } = useParams();

  const [didCancel, setDidCancel] = useState(false);

  const fetchTrial = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios({
        method: "GET",
        url: `${DOMAIN}/manage/trials/`,
        params: {
          id,
        },
        headers: {
          Authorization: getAuthorization(),
        },
      });

      // Check if the component is still mounted before updating state
      if (!didCancel) {
        setTrial(response.data[0]);
      }
    } catch (err) {
      console.log(err);
    } finally {
      // Ensure setLoading is always set to false, whether the request succeeds or fails
      if (!didCancel) {
        setLoading(false);
      }
    }
  }, [id, didCancel]);

  useEffect(() => {
    // Cleanup function to set didCancel to true when unmounting
    return () => {
      setDidCancel(true);
    };
  }, []);

  useEffect(() => {
    // Reset didCancel to false when the component receives a new id prop
    setDidCancel(false);
    fetchTrial();
  }, [fetchTrial]);

  return (
    <Stack
      direction="column"
      spacing={2}
      padding="32px 24px 24px"
      sx={{
        borderLeft: `1px solid ${palette.neutral[300]}`,
        backgroundColor: palette.neutral[100],
        // maxWidth: "640px",
        // minWidth: "320px",
        width: "320px",
      }}
    >
      <Typography variant="c20px600" sx={{ color: palette.neutral[900] }}>
        Trial Information
      </Typography>

      <Loading loading={loading}>
        <>
          <StyledPaper>
            <Stack direction="column" spacing={0.5}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography
                  variant="c14px400"
                  sx={{ color: palette.neutral[700] }}
                >
                  Trial Name
                </Typography>
                <EditTrial
                  createdBy={trial.created_by}
                  trialName={trial.name}
                  trialDescription={trial.description}
                  trialEndDate={trial.end_date}
                  updateValue="name"
                  fetchUpdatedTrial={fetchTrial}
                />
              </Stack>
              <Divider
                sx={{ width: "100%", borderBottom: "1px solid #F7F7F7" }}
              />{" "}
              <Typography
                // variant="c16px500"
                sx={{
                  color: palette.neutral[700],
                  fontSize: "16px",
                  fontWeight: "500",
                }}
              >
                {trial.name}
              </Typography>
            </Stack>
          </StyledPaper>

          <StyledPaper>
            <Stack direction="column" spacing={0.5}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography
                  variant="c14px400"
                  sx={{
                    color: palette.neutral[700],
                  }}
                >
                  Trial Description{" "}
                </Typography>
                <EditTrial
                  createdBy={trial.created_by}
                  trialName={trial.name}
                  trialDescription={trial.description}
                  trialEndDate={trial.end_date}
                  updateValue="description"
                  fetchUpdatedTrial={fetchTrial}
                />
              </Stack>
              <Divider
                sx={{ width: "100%", borderBottom: "1px solid #F7F7F7" }}
              />{" "}
              <Typography
                variant="body1"
                style={{ color: palette.neutral[700] }}
              >
                {trial.description}
              </Typography>
            </Stack>
          </StyledPaper>

          <StyledPaper>
            <Stack direction="column" spacing={0.5}>
              <Typography
                variant="c14px400"
                sx={{
                  color: palette.neutral[700],
                }}
              >
                Trial Created On{" "}
              </Typography>
              <Divider
                sx={{ width: "100%", borderBottom: "1px solid #F7F7F7" }}
              />{" "}
              <Typography
                variant="c16px500"
                sx={{ color: palette.neutral[700] }}
              >
                {moment(trial.created_at).format("D MMMM, YYYY")}
              </Typography>
            </Stack>
          </StyledPaper>

          <StyledPaper>
            <Stack direction="column" spacing={0.5}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography
                  variant="c14px400"
                  sx={{
                    color: palette.neutral[700],
                  }}
                >
                  Expected Trial End Date{" "}
                </Typography>
                <EditTrial
                  createdBy={trial.created_by}
                  trialName={trial.name}
                  trialDescription={trial.description}
                  trialEndDate={trial.end_date}
                  updateValue="endDate"
                  fetchUpdatedTrial={fetchTrial}
                />
              </Stack>
              <Divider
                sx={{ width: "100%", borderBottom: "1px solid #F7F7F7" }}
              />{" "}
              <Typography
                variant="body1"
                style={{ color: palette.neutral[700] }}
              >
                {moment(trial.end_date).format("D MMMM, YYYY")}
              </Typography>
            </Stack>
          </StyledPaper>

          <StyledPaper>
            <Stack direction="column" spacing={0.5}>
              <Typography
                variant="c14px400"
                sx={{
                  color: palette.neutral[700],
                }}
              >
                Total no. of subjects{" "}
              </Typography>
              <Divider
                sx={{ width: "100%", borderBottom: "1px solid #F7F7F7" }}
              />{" "}
              <Typography
                variant="body1"
                style={{ color: palette.neutral[700] }}
              >
                {trial.max_sub}
              </Typography>
            </Stack>
          </StyledPaper>

          <StyledPaper>
            <Stack direction="column" spacing={0.5}>
              <Typography
                variant="c14px400"
                sx={{
                  color: palette.neutral[700],
                }}
              >
                Created By
              </Typography>
              <Divider
                sx={{ width: "100%", borderBottom: "1px solid #F7F7F7" }}
              />{" "}
              <Stack direction="column">
                <Typography
                  variant="c16px500"
                  sx={{
                    color: palette.neutral[700],
                  }}
                >
                  {trial?.created_by_details?.first_name}{" "}
                  {trial?.created_by_details?.last_name}
                </Typography>
                <Typography
                  variant="c14px500"
                  sx={{
                    color: palette.primary[700],
                  }}
                >
                  {trial?.created_by_details?.email}
                </Typography>
              </Stack>
            </Stack>
          </StyledPaper>

          {sharedWith.length ? (
            <StyledPaper>
              <Stack direction="column">
                <Typography
                  variant="c14px400"
                  sx={{
                    color: palette.neutral[700],
                  }}
                >
                  Collaborator(s)
                </Typography>
                <Divider
                  sx={{ width: "100%", borderBottom: "1px solid #F7F7F7" }}
                />{" "}
                {sharedWith.map((item, index) => (
                  <Stack
                    direction="row"
                    key={index}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Stack direction="column" sx={{ paddingTop: "12px" }}>
                      <Typography
                        variant="c16px500"
                        sx={{
                          color: palette.neutral[700],
                        }}
                      >
                        {item.user.first_name} {item.user.last_name}
                      </Typography>
                      <Typography
                        variant="c14px500"
                        sx={{
                          color: palette.primary[700],
                        }}
                      >
                        {item.user.email}
                      </Typography>
                    </Stack>
                    <IconButton
                      size="large"
                      disableRipple
                      disableFocusRipple
                      disableTouchRipple
                      sx={{
                        padding: 0,
                      }}
                    >
                      {item.permissions === "read" ? (
                        <VisibilityOutlined
                          sx={{ height: "16px", width: "16px" }}
                        />
                      ) : (
                        <EditOutlined sx={{ height: "16px", width: "16px" }} />
                      )}
                    </IconButton>
                  </Stack>
                ))}
              </Stack>
            </StyledPaper>
          ) : null}

          <StyledPaper>
            <Stack direction="column" spacing={0.5}>
              <Typography
                variant="c14px400"
                sx={{
                  color: palette.neutral[700],
                }}
              >
                Organisation Name
              </Typography>
              <Divider
                sx={{ width: "100%", borderBottom: "1px solid #F7F7F7" }}
              />{" "}
              <Stack direction="column">
                <Typography
                  variant="c16px500"
                  sx={{
                    color: palette.neutral[700],
                  }}
                >
                  {trial?.org_name}
                </Typography>
              </Stack>
            </Stack>
          </StyledPaper>
        </>
      </Loading>
    </Stack>
  );
}
