import { Stack, CircularProgress } from "@mui/material";

function Loading({ loading, children, height }) {
  return loading ? (
    <Stack
      minHeight={height ? height : "90vh"}
      width="100%"
      justifyContent="center"
      alignItems="center"
      spacing={3}
    >
      <CircularProgress />
    </Stack>
  ) : (
    children
  );
}

export default Loading;
