import { MoreVert } from "@mui/icons-material";
import {
  Button,
  Checkbox,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Modal,
  Stack,
  TextField,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ReactComponent as TrashIcon } from "../../../assets/trash.svg";
import { CustomSelect } from "../../../components/CustomSelect";
import useDevices from "../../../hooks/useDevices";
import { setIsDeviceSaved } from "../../../redux/trials";
import { setNotify } from "../../../redux/utils/utilsSlice";
import { DOMAIN } from "../../../utils/config";
import { getAuthorization } from "../../../utils/helpers";
import { findUniqueFromArrayWith } from "../../../utils/findUnique";

export const CustomTextField = styled(TextField)(({ theme }) => {
  return {
    marginBottom: "20px",
    "label + &": {
      marginTop: theme.spacing(2),
    },
    "& .MuiInputBase-input": {
      borderBottom: "0px",
      fontSize: 16,
      padding: "10px 12px",
      backgroundColor: theme.palette.neutral[100],
      border: `2px solid ${theme.palette.neutral[400]}`,
      borderRadius: "6px",
      "&:focus": {
        borderColor: theme.palette.primary[600],
        boxShadow: `0 0 0 2px #0C6FE280`,
      },
    },
    "& .MuiInputBase-root.MuiFilledInput-root": {
      padding: 0,
    },
    overflow: "hidden",
    borderRadius: "4px",
  };
});

export const CustomInputLabel = ({ label, required }) => {
  const { palette } = useTheme();

  return (
    <InputLabel
      variant="standard"
      shrink={false}
      style={{
        marginTop: "-30px",
        width: "100%",
      }}
    >
      <Typography
        variant="c16px500"
        style={{
          color: palette.neutral[500],
        }}
      >
        {label}{" "}
        {required && <span style={{ color: palette.neutral[500] }}>*</span>}
      </Typography>
    </InputLabel>
  );
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function UnapprovedDevice(props) {
  const { palette } = useTheme();
  const { id } = useParams();
  const dispatch = useDispatch();

  const { devices } = useDevices();

  const saved = useSelector((state) =>
    state.trials.trial.trialDevices.find(
      (device) => device.id === props.device?.id
    )
  ).saved;

  const [services, setServices] = useState([]);

  const [device, setDevice] = useState(props?.device?.device_details?.id || "");
  const [serviceName, setServiceName] = useState([]);
  const [subjects, setSubjects] = useState(props?.device?.subm_per_subj || "");

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSelectServices = (event) => {
    const {
      target: { value },
    } = event;
    setServiceName(typeof value === "string" ? value.split(",") : value);

    const removedName = serviceName.find((service) => !value.includes(service));
    if (removedName) {
      const service = props.device?.trialdeviceservice_set.find(
        (service) => service.service_details.name === removedName
      );
      removeServiceFromDevice({ serviceId: service.id });
    }

    const addedName = value.find((service) => !serviceName.includes(service));
    if (addedName) {
      const service = services.find((service) => service.name === addedName);
      addServiceToDevice({ serviceId: service.id });
    }

    dispatch(
      setIsDeviceSaved({
        id: props.device?.id,
        saved: false,
      })
    );
  };

  const handleUpdateDevice = async () => {
    const deviceRes = await updateTrialDevice(props.device?.id, subjects);
    const filteredServices = props.device?.trialdeviceservice_set.filter(
      (service) => serviceName.includes(service.name)
    );
    filteredServices.map(
      async (service) =>
        await updateTrialDeviceService(
          deviceRes.id,
          deviceRes.device,
          service.id
        )
    );
    dispatch(
      setIsDeviceSaved({
        id: props.device?.id,
        saved: true,
      })
    );
  };

  const handleDeviceChange = (e) => {
    const deviceId = e.target.value;
    setDevice(deviceId);

    setServiceName([]);

    const services = devices.find(
      (device) => device.id === deviceId
    ).service_set;
    setServices(services);

    dispatch(
      setIsDeviceSaved({
        id: props.device?.id,
        saved: false,
      })
    );
  };

  const handleDeleteDevice = async () => {
    try {
      const res = await axios({
        method: "DELETE",
        url: `${DOMAIN}/manage/trials/devices/`,
        params: {
          trial_id: id,
          id: props.device?.id,
        },
        headers: {
          Authorization: getAuthorization(),
        },
      });
      if (res.status === 204 || res.status === 200) {
        dispatch(
          setNotify({
            open: true,
            action: "Device Deleted Successfully!",
            severity: "success",
            autoHideDuration: 3000,
            vertical: "bottom",
            horizontal: "right",
          })
        );
      }
      props.fetchTrialDeviceList();
    } catch (err) {
      console.log(err);
    }
    handleClose();
  };

  useEffect(() => {
    const filteredServices = props.device?.trialdeviceservice_set.filter(
      (service) => service.is_active
    );
    setServiceName(
      filteredServices.map((service) => service.service_details.name)
    );
  }, [props?.device]);

  const addServiceToDevice = async ({ serviceId }) => {
    try {
      const res = await axios({
        method: "POST",
        url: `${DOMAIN}/manage/trials/devices/services/`,
        params: {
          trial_id: id,
          trial_device_id: props.device?.id,
          service_id: serviceId,
        },
        headers: {
          Authorization: getAuthorization(),
        },
        data: {
          trial_device: props.device?.device,
          service: serviceId,
        },
      });
      if (res.status === 201 || res.status === 200) {
        dispatch(
          setNotify({
            open: true,
            action: "Device Services Added Successfully!",
            severity: "success",
            autoHideDuration: 3000,
            vertical: "bottom",
            horizontal: "right",
          })
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const removeServiceFromDevice = async ({ serviceId }) => {
    try {
      const res = await axios({
        method: "DELETE",
        url: `${DOMAIN}/manage/trials/devices/services/`,
        params: {
          trial_id: id,
          trial_device_id: props.device?.id,
          id: serviceId,
        },
        headers: {
          Authorization: getAuthorization(),
        },
      });
      if (res.status === 204 || res.status === 200) {
        dispatch(
          setNotify({
            open: true,
            action: "Device Services Deleted Successfully!",
            severity: "success",
            autoHideDuration: 3000,
            vertical: "bottom",
            horizontal: "right",
          })
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const updateTrialDevice = async (deviceId, subject) => {
    try {
      const data = {
        trial: Number(id),
        device: deviceId,
      };

      if (subject !== null) {
        data.subm_per_subj = Number(subject);
      }

      const res = await axios({
        method: "PATCH",
        url: `${DOMAIN}/manage/trials/devices/`,
        params: {
          trial_id: id,
          id: deviceId,
        },
        headers: {
          Authorization: getAuthorization(),
        },
        data,
      });
      if (res.status === 200) {
        dispatch(
          setNotify({
            open: true,
            action: "Device Updated Successfully!",
            severity: "success",
            autoHideDuration: 3000,
            vertical: "bottom",
            horizontal: "right",
          })
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchDeviceServices = useCallback(async () => {
    try {
      const res = await axios({
        method: "GET",
        url: `${DOMAIN}/manage/devices/`,
        params: {
          id: props.device?.device,
        },
        headers: {
          Authorization: getAuthorization(),
        },
      });
      setServices(res.data[0].service_set);
    } catch (err) {
      console.log(err);
    }
  }, [props.device?.device]);

  useEffect(() => {
    fetchDeviceServices();
  }, [fetchDeviceServices]);

  const updateTrialDeviceService = async (deviceId, device, serviceId) => {
    try {
      const res = await axios({
        method: "PATCH",
        url: `${DOMAIN}/manage/trials/devices/services/`,
        params: {
          trial_id: id,
          trial_device_id: deviceId,
          id: serviceId,
        },
        headers: {
          Authorization: getAuthorization(),
        },
        data: {
          trial_device: device,
          service: serviceId,
        },
      });
      if (res.status === 200) {
        dispatch(
          setNotify({
            open: true,
            action: "Device Services Updated Successfully!",
            severity: "success",
            autoHideDuration: 3000,
            vertical: "bottom",
            horizontal: "right",
          })
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Stack
      p={3}
      spacing={1}
      sx={{
        borderRadius: 1,
        background: palette.neutral[100],
        border: `1px solid ${palette.neutral[300]}`,
        width: "688px",
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        paddingBottom={2}
      >
        <Typography variant="c16px600">Device Settings</Typography>
        <IconButton
          sx={{
            height: "32px",
            width: "32px",
            padding: "10px",
            borderRadius: "6px",
            background: palette.neutral[200],
          }}
          onClick={handleOpen}
        >
          <MoreVert fontSize="large" />
        </IconButton>
        <Modal
          aria-labelledby="Device Settings"
          sx={{
            position: "absolute",
            left: "32%",
            bottom: "40%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          open={open}
          onClose={handleClose}
        >
          <Stack
            sx={{
              width: "162px",
              borderRadius: "6px",
              padding: "16px",
              backgroundColor: palette.neutral[100],
              border: `1px solid ${palette.neutral[300]}`,
              boxShadow: "0px 8px 16px 0px rgba(188, 188, 188, 0.25)",
            }}
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="c16px500" color={palette.error.main}>
              Delete
            </Typography>
            <TrashIcon cursor="pointer" onClick={handleDeleteDevice} />
          </Stack>
        </Modal>
      </Stack>
      <FormControl fullWidth>
        <CustomInputLabel label="Devices" />
        <CustomSelect
          fullWidth
          displayEmpty
          value={device}
          onChange={handleDeviceChange}
          disabled={!!device}
        >
          <MenuItem value="" disabled>
            Select a device
          </MenuItem>
          {findUniqueFromArrayWith(
            devices,
            props.trialDevices,
            props.device?.device
          ).map((device) => (
            <MenuItem key={device.id} value={device.id}>
              {device.name}
            </MenuItem>
          ))}
        </CustomSelect>
      </FormControl>
      <FormControl fullWidth>
        <CustomInputLabel label="Services" />
        <CustomSelect
          multiple
          fullWidth
          displayEmpty
          value={serviceName}
          onChange={handleSelectServices}
          renderValue={(selected) =>
            selected.length === 0 ? "Select services" : selected.join(", ")
          }
          MenuProps={MenuProps}
        >
          <MenuItem value="" disabled>
            Select services
          </MenuItem>
          {services.map((service) => (
            <MenuItem key={service.id} value={service.name}>
              <Checkbox checked={serviceName.indexOf(service.name) > -1} />
              <ListItemText primary={service.name} />
            </MenuItem>
          ))}
        </CustomSelect>
      </FormControl>
      <FormControl fullWidth>
        <CustomInputLabel label="Submissions per subject" required />
        <CustomTextField
          //   error={titleError ? true : false}
          //   helperText={titleError}
          fullWidth
          variant="filled"
          placeholder="Enter no. of submissions per subject"
          type="text"
          InputProps={{ disableUnderline: true }}
          value={subjects}
          onChange={(e) => {
            setSubjects(e.target.value);
            dispatch(
              setIsDeviceSaved({
                id: props.device?.id,
                saved: false,
              })
            );
          }}
          required
        />
      </FormControl>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        {saved ? (
          <Typography variant="c16px500" color={palette.neutral[700]}>
            Changes Saved
          </Typography>
        ) : (
          <Typography
            variant="c16px500"
            color={palette.neutral[700]}
            sx={{
              backgroundColor: palette.primary[200],
              padding: "4px 8px",
              borderRadius: "4px",
            }}
          >
            Unsaved Changes
          </Typography>
        )}
        <Button
          data-cy="button__save"
          variant="contained"
          type="submit"
          disabled={saved}
          sx={{
            textTransform: "capitalize",
            width: "fit-content",
            fontSize: "16px",
            fontWeight: "500",
            color: palette.neutral[100],
            borderRadius: "6px",
            backgroundColor: palette.primary[500],
            padding: "10px 16px",
            lineHeight: "19.36px",
            "&:hover": {
              color: palette.neutral[100],
              backgroundColor: palette.primary[500],
            },
          }}
          onClick={handleUpdateDevice}
        >
          Save
        </Button>
      </Stack>
    </Stack>
  );
}
