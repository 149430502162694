import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Loading from "../../../components/Blockers/Loading";
import { setTrialDevices } from "../../../redux/trials";
import { DOMAIN } from "../../../utils/config";
import { getAuthorization } from "../../../utils/helpers";
import AddDeviceModal from "./AddDeviceModal";
import ApprovedDevice from "./ApprovedDevice";
import UnapprovedDevice from "./UnapprovedDevice";

export default function TrialDeviceList({ status }) {
  const { id } = useParams();
  const dispatch = useDispatch();

  const [devices, setDevices] = useState([]);

  const [loading, setLoading] = useState(false);

  const [didCancel, setDidCancel] = useState(false);

  const fetchTrialDeviceList = useCallback(async () => {
    setLoading(true);
    try {
      const res = await axios({
        method: "GET",
        url: `${DOMAIN}/manage/trials/devices/`,
        params: {
          trial_id: id,
        },
        headers: {
          Authorization: getAuthorization(),
        },
      });
      if (!didCancel) {
        setDevices(res.data);
        dispatch(
          setTrialDevices(
            res.data.map((device) => ({ id: device.id, saved: true }))
          )
        );
      }
    } catch (err) {
      console.log(err);
    } finally {
      if (!didCancel) {
        setLoading(false);
      }
    }
  }, [id, dispatch, didCancel]);

  useEffect(() => {
    // Cleanup function to set didCancel to true when unmounting
    return () => {
      setDidCancel(true);
    };
  }, []);

  useEffect(() => {
    setDidCancel(false);
    fetchTrialDeviceList();
  }, [fetchTrialDeviceList]);

  return (
    <Loading loading={loading}>
      {devices.map((device) =>
        status === "approved" || status === "live" ? (
          <ApprovedDevice
            key={device.id}
            device={device}
            fetchTrialDeviceList={fetchTrialDeviceList}
          />
        ) : (
          <UnapprovedDevice
            key={device.id}
            device={device}
            trialDevices={devices}
            fetchTrialDeviceList={fetchTrialDeviceList}
          />
        )
      )}
      <AddDeviceModal
        trialDevices={devices}
        fetchTrialDeviceList={fetchTrialDeviceList}
      />
    </Loading>
  );
}
