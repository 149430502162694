import { Button, Dialog, Stack, Switch, Typography } from "@mui/material";
import { useState } from "react";
import {
  CustomDialogContent,
  CustomDialogTitle,
} from "../../../components/CustomDialog";
import { getAuthorization } from "../../../utils/helpers";
import axios from "axios";
import { DOMAIN } from "../../../utils/config";
import { useParams } from "react-router-dom";

export default function DeviceOnOff(props) {
  const { id } = useParams();

  const [isChecked, setIsChecked] = useState(props.isEnabled || false);
  const [open, setOpen] = useState(false);

  const handleSwitchChange = () => {
    setIsChecked((prevState) => !prevState);
    setOpen(false);
    handleEnableDevice();
  };

  const handleEnableDevice = async () => {
    try {
      await axios({
        method: "PATCH",
        url: `${DOMAIN}/manage/trials/devices/`,
        params: { trial_id: id, id: props.id },
        data: {
          is_enabled: !isChecked,
          trial: id,
          device: props.device,
        },
        headers: {
          Authorization: getAuthorization(),
        },
      });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <Switch
        checked={isChecked}
        onClick={() => setOpen(true)}
        color="primary"
      />
      <Dialog open={open} onClose={() => setOpen(false)}>
        <CustomDialogTitle>Turn on/off Device</CustomDialogTitle>
        <CustomDialogContent>
          <Typography sx={{ mb: 3 }}>
            {`Are you sure you want to turn the device '${props.name}' ${
              isChecked ? "off" : "on"
            }?`}
          </Typography>
          <Stack display="flex" alignItems="center" direction="row" gap={2}>
            <Button
              variant="outlined"
              sx={{
                flex: 1,
              }}
              onClick={() => setOpen(false)}
            >
              No
            </Button>
            <Button
              variant="contained"
              sx={{
                flex: 1,
              }}
              onClick={handleSwitchChange}
            >
              Yes
            </Button>
          </Stack>
        </CustomDialogContent>
      </Dialog>
    </>
  );
}
