import { Grid, Stack, useTheme } from "@mui/material";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Loading from "../../../components/Blockers/Loading";
import useHideScrollbar from "../../../hooks/useHideScrollbar";
import { setTrialApprovable } from "../../../redux/trials";
import { DOMAIN } from "../../../utils/config";
import { getAuthorization } from "../../../utils/helpers";
import ApprovedNavbar from "./ApprovedNavbar";
import TrialDeviceList from "./TrialDeviceList";
import TrialInfo from "./TrialInfo";
import UnapprovedNavbar from "./UnapprovedNavbar";

function Trial() {
  const { palette } = useTheme();
  const { id } = useParams();
  const dispatch = useDispatch();
  dispatch(setTrialApprovable(true));

  const [trial, setTrial] = useState({});

  useHideScrollbar();

  const [loading, setLoading] = useState(false);

  const fetchTrial = useCallback(async () => {
    setLoading(true);
    try {
      const res = await axios({
        method: "GET",
        url: `${DOMAIN}/manage/trials/`,
        params: {
          id,
        },
        headers: {
          Authorization: getAuthorization(),
        },
      });
      setTrial(res.data[0]);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    fetchTrial();
  }, [fetchTrial]);

  return (
    <Loading loading={loading}>
      <Stack
        height="100vh"
        sx={{
          alignItems: "center",
        }}
      >
        <Stack width="100%" position="fixed" top="0" zIndex="1">
          {trial.status !== "requested" ? (
            <ApprovedNavbar trialName={trial.name} status={trial.status} />
          ) : (
            <UnapprovedNavbar trialName={trial.name} />
          )}
        </Stack>
        {/* <Stack direction="row"> */}
        <Stack
          style={{
            overflowY: "auto",
            paddingTop: "72px",
          }}
        >
          <Grid
            container
            style={{
              padding: "32px 32px 59px 32px",
            }}
            spacing={3}
          >
            <Stack direction="column" gap={4} py={3}>
              <TrialDeviceList status={trial.status} />
            </Stack>
          </Grid>
        </Stack>
        <Stack
          sx={{
            backgroundColor: palette.neutral[100],
            borderLeft: `1px solid ${palette.neutral[300]}`,
            position: "absolute",
            right: 0,
            zIndex: 0,
            height: "100%",
            paddingTop: "72px",
            overflowY: "auto",
          }}
        >
          <TrialInfo />
        </Stack>
      </Stack>
      {/* </Stack> */}
    </Loading>
  );
}

export default Trial;
