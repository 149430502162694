import { useState } from "react";

import { useDispatch } from "react-redux";
import { setNotify } from "../../redux/utils/utilsSlice";

import axios from "axios";
import { useNavigate } from "react-router-dom";
import urls from "../../utils/urls.json";
import { saveUserCredentials } from "../../utils/helpers";
import { DOMAIN } from "../../utils/config";

import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  Stack,
  TextField as TextFieldBase,
  Typography,
} from "@mui/material";

import { styled } from "@mui/material/styles";

import trialImg from "../../assets/Trial.png";
import trialImgDots from "../../assets/Frame 20.svg";

const TextField = styled(TextFieldBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(2),
  },
  "& .MuiInputBase-input": {
    borderBottom: "0px",
    fontSize: 16,
    padding: "10px 12px",
  },
}));

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // redux
  const dispatch = useDispatch();

  // react-router-dom navigator
  const navigator = useNavigate();

  const handleSubmit = async (e) => {
    // handle form submit

    e.preventDefault();
    // remove errors from state, if any
    setError(null);
    // set loading to true
    setLoading(true);

    try {
      // make API request
      const response = await axios({
        method: "POST",
        url: `${DOMAIN}/auth/login/`,
        data: {
          email,
          password,
        },
      });
      if (response.data.user.is_staff) {
        // save user credentials
        saveUserCredentials(response.data);

        // notify
        dispatch(
          setNotify({
            open: true,
            action: "Logged in Successfully!",
            severity: "success",
            autoHideDuration: 5000,
            vertical: "bottom",
            horizontal: "right",
          })
        );
        // redirect to home page
        navigator(urls.home.path);
      } else {
        setError("Unable to log in with provided credentials");
      }
    } catch (err) {
      // set loading to false
      setLoading(false);
      try {
        // fetch error
        let error_object = JSON.parse(err.request.response);
        // set error
        setError(error_object.non_field_errors[0]);
      } catch {
        // default error message
        setError(["Unable to log in with provided credentials"]);
      }
    }
  };

  const handleChange = (func, value) => {
    // handle change of input values

    // remove errors from state, if anny
    setError(null);
    func(value);
  };

  return (
    <Grid
      container
      style={{
        height: "100vh",
      }}
    >
      <Grid item xs={12} lg={5}>
        <Box
          px={2}
          style={{
            height: "100vh",
            backgroundColor: "#fff",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Stack
            direction="column"
            spacing={1}
            py={5}
            style={{
              width: "100%",
              maxWidth: "400px",
            }}
          >
            <Typography
              style={{
                fontSize: "32px",
                fontWeight: "700",
                lineHeight: "38px",
                letterSpacing: "0em",
                textAlign: "left",
              }}
            >
              Log In
            </Typography>
            <Typography
              style={{
                fontSize: "20px",
                fontWeight: "500",
                lineHeight: "24px",
                letterSpacing: "0em",
                textAlign: "left",
              }}
            >
              Welcome back!
            </Typography>
            <form autoComplete="off" onSubmit={handleSubmit}>
              <Stack my={2} direction="column" spacing={2}>
                <FormControl>
                  <InputLabel shrink variant="standard">
                    Enter Your Email ID
                  </InputLabel>
                  <TextField
                    error={error ? true : false}
                    helperText={error}
                    fullWidth
                    variant="filled"
                    placeholder="Email ID"
                    type="email"
                    value={email}
                    onChange={(e) => handleChange(setEmail, e.target.value)}
                    InputProps={{ disableUnderline: true }}
                    required
                  />
                </FormControl>
                <FormControl>
                  <InputLabel shrink variant="standard">
                    Enter Your Password
                  </InputLabel>
                  <TextField
                    error={error ? true : false}
                    helperText={error}
                    fullWidth
                    variant="filled"
                    placeholder="Password"
                    type="password"
                    value={password}
                    onChange={(e) => handleChange(setPassword, e.target.value)}
                    InputProps={{ disableUnderline: true }}
                    required
                  />
                </FormControl>
                <Button
                  type="submit"
                  disableElevation
                  disabled={loading}
                  size="large"
                  color="primary"
                  variant="contained"
                >
                  {loading ? "Logging In" : "Log In"}
                </Button>
              </Stack>
            </form>
          </Stack>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        lg={7}
        style={{
          backgroundColor: "#0B3A73",
          height: "100%",
          width: "100%",
        }}
      >
        <Box
          px={2}
          style={{
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Stack
            justifyContent="center"
            alignItems="center"
            spacing={4}
            style={{
              width: "100%",
              maxWidth: "800px",
            }}
          >
            <img src={trialImg} alt="Trial" width="100%" />
            <img src={trialImgDots} alt="Trial Dots" width="30px" />
            <Stack
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}
            >
              <Typography
                style={{
                  color: "#fff",
                  fontSize: "16px",
                  fontWeight: "600",
                  lineHeight: "19px",
                  letterSpacing: "0em",
                  textAlign: "left",
                }}
              >
                CONDUCT TRIALS
              </Typography>
              <Typography
                style={{
                  color: "#fff",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "17px",
                  letterSpacing: "0em",
                  textAlign: "left",
                }}
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
                eu turpis molestie, dictum est a, mattis tellus. Sed dignissim,
                metus nec fringilla accumsan, risus sem sollicitudin lacus, ut
                interdum tellus elit sed risus. Maecenas eget condimentum velit,
                sit amet feugiat lectus.
              </Typography>
            </Stack>
          </Stack>
        </Box>
      </Grid>
    </Grid>
  );
}
