import axios from "axios";
import { createContext, useCallback, useEffect, useState } from "react";
import { DOMAIN } from "../utils/config";
import { getAuthorization } from "../utils/helpers";

export const DevicesContext = createContext({
  devices: [],
});

const DevicesProvider = (props) => {
  const [devices, setDevices] = useState([]);

  const fetchDevices = useCallback(async () => {
    try {
      const res = await axios({
        method: "GET",
        url: `${DOMAIN}/manage/devices/`,
        headers: {
          Authorization: getAuthorization(),
        },
      });
      setDevices(res.data);
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    fetchDevices();
  }, [fetchDevices]);

  return (
    <DevicesContext.Provider value={{ devices }}>
      {props.children}
    </DevicesContext.Provider>
  );
};

export default DevicesProvider;
