import { Button, Stack, Typography, useTheme } from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { setNotify } from "../../../redux/utils/utilsSlice";
import { DOMAIN } from "../../../utils/config";
import { getAuthorization } from "../../../utils/helpers";

export default function UnapprovedNavbar({ trialName }) {
  const { palette } = useTheme();
  const { id } = useParams();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const approvable = useSelector((state) => state.trials.trial.approvable);

  const [label, setLabel] = useState("Approve Trial");

  async function approveTrial() {
    setLabel("Approving...");
    try {
      const res = await axios({
        method: "POST",
        url: `${DOMAIN}/manage/trials/approve/`,
        params: {
          id,
        },
        headers: {
          Authorization: getAuthorization(),
        },
      });
      if (res.status === 200) {
        dispatch(
          setNotify({
            open: true,
            action: "Trial Approved Successfully!",
            severity: "success",
            autoHideDuration: 3000,
            vertical: "bottom",
            horizontal: "right",
          })
        );
        setLabel("Approved");
        navigate("/");
      }
    } catch (err) {
      console.log(err);
      setLabel("Approve Trial");
    }
  }

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      px={4}
      py={3}
      sx={{
        backgroundColor: palette.neutral[100],
        boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.07)",
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing={1.5}
      >
        <Link to="/">
          <Typography variant="c18px400" color={palette.neutral[700]}>
            All Trials
          </Typography>
        </Link>
        <Typography mx={2}>/</Typography>
        <Typography variant="c20px600" color={palette.neutral[900]}>
          {trialName}
        </Typography>
        <Typography
          variant="c14px600"
          color="#8D1A1A"
          sx={{
            padding: "4px 8px",
            background: "#FFE7E7",
            borderRadius: "4px",
          }}
        >
          Unapproved
        </Typography>
      </Stack>
      <Stack>
        <Button
          disabled={!approvable}
          variant="contained"
          color="primary"
          sx={{ p: "10px 16px", borderRadius: "6px" }}
          onClick={approveTrial}
        >
          {label}
        </Button>
      </Stack>
    </Stack>
  );
}
