import { Stack, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import { getTrialStatus } from "../../../utils/status";

export default function ApprovedNavbar({ trialName, status }) {
  const { palette } = useTheme();

  return (
    <Stack
      direction="row"
      alignItems="center"
      px={4}
      py={3}
      spacing={1.5}
      sx={{
        backgroundColor: palette.neutral[100],
        boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.07)",
      }}
    >
      <Link to="/">
        <Typography variant="c18px400" color={palette.neutral[700]}>
          All Trials
        </Typography>
      </Link>
      <Typography mx={2}>/</Typography>
      <Typography variant="c20px600" color={palette.neutral[900]}>
        {trialName}
      </Typography>
      {status && (
        <Typography
          variant="c14px600"
          color={getTrialStatus(status).color}
          sx={{
            background: getTrialStatus(status).backgroundColor,
            borderRadius: "4px",
            padding: "4px 8px",
          }}
        >
          {getTrialStatus(status).label}
        </Typography>
      )}
    </Stack>
  );
}
