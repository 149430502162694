// Save User Credentials
export const saveUserCredentials = (data) => {
  // helper function to save user credentials to the local storage
  localStorage.setItem("access_token", data.access);
  localStorage.setItem("refresh_token", data.refresh);
  localStorage.setItem("user", JSON.stringify(data.user));
};

// Delete User Credentials
export const deleteUserCredentials = (data) => {
  // helper function to delete user credentials from local storage
  localStorage.removeItem("access_token");
  localStorage.removeItem("refresh_token");
  localStorage.removeItem("user");
};

// Get User Credentials
export const getUserCredentials = () => {
  // helper function to fetch user credentials from local storage
  let user = localStorage.getItem("user");
  if (user) {
    user = JSON.parse(user);
  } else {
    user = {};
  }
  return {
    accessToken: localStorage.getItem("access_token"),
    refreshToken: localStorage.getItem("refresh_token"),
    user,
  };
};

export const getAuthorization = () => {
  const data = getUserCredentials();
  return `Bearer ${data["accessToken"]}`;
};

export function generateFilters(filters) {
  const filterObject = {};

  // Check if the "All" filter is the only one in the array
  if (filters.length === 1 && filters[0] === "All") {
    return filterObject; // Return an empty object
  }

  // Iterate through the filters array and set the corresponding keys to true
  filters.forEach((filter) => {
    // Convert the filter name to lowercase and add "status_" as a prefix
    const key =
      filter === "Unapproved"
        ? "status_requested"
        : `status_${filter.toLowerCase()}`;
    filterObject[key] = true;
  });

  return filterObject;
}
