import { configureStore } from "@reduxjs/toolkit";
import auth from "./auth/authSlice";
import utils from "./utils/utilsSlice";
import trials from "./trials";

export default configureStore({
  reducer: {
    auth,
    utils,
    trials,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});
