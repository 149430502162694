import { useEffect } from "react";

const useHideScrollbar = () => {
  const hideScrollbar = () => {
    // Check if the browser supports Webkit scrollbars
    if ("WebkitAppearance" in document.documentElement.style) {
      const style = document.createElement("style");
      style.innerHTML = `
        ::-webkit-scrollbar {
          width: 0 !important;
          height: 0 !important;
        }
      `;
      document.head.appendChild(style);

      // Return a function to remove the added style when unmounting
      return () => {
        document.head.removeChild(style);
      };
    }
  };

  useEffect(() => {
    const removeScrollbar = hideScrollbar();

    // Clean up the added style when the component unmounts
    return () => {
      removeScrollbar();
    };
  }, []);
};

export default useHideScrollbar;
