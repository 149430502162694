import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  approvable: false,
  trialDevices: [],
};

export const trialSlice = createSlice({
  name: "trial",
  initialState,
  reducers: {
    setTrialApprovable: (state, action) => {
      return {
        ...state,
        approvable: action.payload,
      };
    },
    setTrialDevices: (state, action) => {
      return {
        ...state,
        trialDevices: action.payload,
      };
    },
    setIsDeviceSaved: (state, action) => {
      const updatedTrialDevices = state.trialDevices.map((device) => {
        if (device.id === action.payload.id) {
          return {
            ...device,
            saved: action.payload.saved,
          };
        }
        return device;
      });

      return {
        ...state,
        trialDevices: updatedTrialDevices,
        approvable: updatedTrialDevices.every((device) => device.saved),
      };
    },
  },
});

export const { setTrialApprovable, setTrialDevices, setIsDeviceSaved } =
  trialSlice.actions;

export default trialSlice.reducer;
