import {
  Box,
  Button,
  FormControl,
  Grid,
  Stack,
  Typography,
  // makeStyles,
  useTheme,
} from "@mui/material";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import logoutIcon from "../../../assets/icons/logout.svg";
import logo from "../../../assets/logo/logo.svg";
import Loading from "../../../components/Blockers/Loading";
import { setActiveTab } from "../../../redux/utils/utilsSlice";
import { DOMAIN } from "../../../utils/config";
import { generateFilters, getAuthorization } from "../../../utils/helpers";

import { logout } from "../../../redux/auth/authSlice";
import { setNotify } from "../../../redux/utils/utilsSlice";
// utils

import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import moment from "moment";
import * as React from "react";
import { getTrialStatus } from "../../../utils/status";
import urls from "../../../utils/urls.json";

// import { makeStyles } from "@mui/styles";

const filterOptions = [
  "All",
  "Unapproved",
  "Approved",
  "Live",
  "Completed",
  "Ended",
];

function Trials() {
  const [loading, setLoading] = useState(true);
  const [trials, setTrials] = useState([]);
  const { palette } = useTheme();

  // react-router-dom navigator
  const navigator = useNavigate();
  const navigate = useNavigate();
  const [filters, setFilters] = useState([filterOptions[0]]);

  // redux
  const dispatch = useDispatch();

  const handleChange = (event, change) => {
    const allSelected = change?.props?.value === filterOptions[0];

    if (allSelected) {
      setFilters([filterOptions[0]]);
      return;
    }

    const value = event.target.value;
    if (!value.length) {
      setFilters([filterOptions[0]]);
      return;
    }
    if (value.length > 1) {
      setFilters(value.filter((i) => i !== filterOptions[0]));
      return;
    }
    setFilters(value);
  };

  const fetchTrials = useCallback(async () => {
    setLoading(true);
    const filterParams = generateFilters(filters);
    try {
      const res = await axios({
        method: "GET",
        url: `${DOMAIN}/manage/trials/`,
        params: {
          ...filterParams,
        },
        headers: {
          Authorization: getAuthorization(),
        },
      });
      setTrials(res.data.filter((d) => d.status !== "init"));
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  }, [filters]);

  useEffect(() => {
    dispatch(setActiveTab("trials"));
  }, [dispatch]);

  useEffect(() => {
    fetchTrials();
  }, [fetchTrials]);

  const Card = (props) => (
    <Grid item xs={12} sm={6} lg={4}>
      <Stack
        direction="column"
        justifyContent="space-between"
        padding={3}
        height="256px"
        minWidth="356px"
        maxWidth="640px"
        onClick={() => {
          navigator(`trial/${props.id}`);
        }}
        spacing={2}
        sx={{
          border: `1px solid ${palette.neutral[300]}`,
          "&:hover": {
            border: `1px solid ${palette.primary[500]}`,
          },
          "&:clicked": {
            border: `1.5px solid ${palette.primary[500]}`,
          },
          borderRadius: "6px",
          backgroundColor: palette.neutral[100],
          cursor: "pointer",
        }}
      >
        <Stack direction="column" spacing={2}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="c16px500" sx={{ color: palette.neutral[700] }}>
              Status
            </Typography>
            <Box
              sx={{
                display: "flex",
                padding: "4px 8px",
                alignItems: "flex-start",
                gap: "10px",
                backgroundColor: getTrialStatus(props.status).backgroundColor,
                borderRadius: "4px",
              }}
            >
              <Typography
                variant="c14px600"
                sx={{ color: getTrialStatus(props.status).color }}
              >
                {getTrialStatus(props.status).label}
              </Typography>
            </Box>
          </Stack>
          <Typography
            variant="c20px600"
            style={{
              color: palette.neutral[900],
              display: "-webkit-box",
              overflow: "hidden",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 1,
              textOverflow: "ellipsis",
            }}
          >
            {props.name}
          </Typography>
          <Typography
            variant="c16px400"
            style={{
              textAlign: "left",
              color: palette.neutral[500],
              display: "-webkit-box",
              overflow: "hidden",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 2,
              textOverflow: "ellipsis",
            }}
          >
            {props.description}
          </Typography>
        </Stack>

        <Stack direction="column" spacing={1}>
          <Stack direction="row" spacing={1}>
            <Typography variant="c16px500">Organisation:</Typography>
            <Typography variant="c16px600">{props.org_name}</Typography>
          </Stack>
          <Stack direction="row" spacing={1}>
            <Typography variant="c16px500">Created On:</Typography>
            <Typography variant="c16px600">
              {moment(props.created_at).format("D MMM, YYYY")}
            </Typography>
          </Stack>
          <Stack direction="row" spacing={1}>
            <Typography variant="c16px500">Expected End Date:</Typography>
            <Typography variant="c16px600">
              {moment(props.end_date).format("D MMM, YYYY")}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      {/* </Stack> */}
    </Grid>
  );

  return (
    <Grid
      container
      style={{
        height: "100%",
        minHeight: "100vh",
      }}
    >
      <Loading loading={loading}>
        <>
          {/* START: Top Nav */}
          <Grid item xs={12}>
            <Stack
              direction="row"
              justifyContent="space-between"
              spacing={2}
              alignItems="center"
              px={{ xs: "32px", ll: "48px" }}
              py={2}
              // component={Paper}
              style={{
                position: "sticky",
                top: "0",
                backgroundColor: palette.neutral[100],
                zIndex: 101,
                width: "100%",
                boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.07)",
              }}
            >
              <img src={logo} alt="" />
              <Button
                variant="text"
                size="small"
                color="primary"
                endIcon={<img src={logoutIcon} alt="" />}
                onClick={() => {
                  // logout on click
                  dispatch(logout());

                  // notify
                  dispatch(
                    setNotify({
                      open: true,
                      action: "Logged out Successfully!",
                      severity: "success",
                      autoHideDuration: 5000,
                      vertical: "bottom",
                      horizontal: "right",
                    })
                  );

                  // redirect to login page
                  navigate(urls.login.path);
                }}
              >
                Logout
              </Button>
            </Stack>
            {/* END: Top Nav */}
            <Grid
              container
              style={{
                padding: "32px",
                zIndex: 100,
                backgroundColor: palette.primary[100],
                display: "grid",
              }}
              // spacing={3}
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                sx={{ width: "100%" }}
              >
                <Typography
                  variant="c24px600"
                  sx={{ color: palette.neutral[900] }}
                >
                  All Trials
                </Typography>

                <FormControl variant="outlined">
                  <Select
                    variant="outlined"
                    onChange={handleChange}
                    multiple
                    value={filters}
                    defaultValue={[filterOptions[0]]}
                    renderValue={() => "Filter"}
                    sx={{ height: "36px" }}
                  >
                    {filterOptions.map((name) => (
                      <MenuItem
                        key={name}
                        value={name}
                        sx={{
                          borderBottom:
                            name === filterOptions[0]
                              ? "1px solid #e2e2e2"
                              : "none ",
                        }}
                      >
                        <Checkbox checked={filters.indexOf(name) > -1} />
                        <ListItemText primary={name} />
                      </MenuItem>
                    ))}
                    {/* <Button
                      sx={{
                        textAlign: "center",
                      }}
                      variant="contained"
                      size="small"
                      color="primary"
                    >
                      Select
                    </Button> */}
                  </Select>
                </FormControl>
              </Stack>
              <Grid
                container
                spacing={3}
                style={{
                  paddingTop: "16px",
                  height: "100%",
                  minHeight: "100vh",
                }}
              >
                {trials?.map((item, index) => (
                  <Card key={index} {...item} />
                ))}
              </Grid>
            </Grid>
          </Grid>
        </>
      </Loading>
    </Grid>
  );
}

export default Trials;
