import { BrowserRouter, Routes, Route } from "react-router-dom";
import ProtectedRoute from "./hoc/ProtectedRoute/Index";
import RedirectIfAuth from "./hoc/RedirectIfAuth/Index";

import urls from "../src/utils/urls.json";

// pages
import Trials from "./containers/Pages/Trials";
import Trial from "./containers/Pages/Trial";
import Login from "./containers/Auth/Login";

// providers
import DevicesProvider from "./context/DevicesContext";

export default function MyRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path={urls.home.path}
          element={<ProtectedRoute children={<Trials />} />}
        />
        <Route
          path={urls.trial.path}
          element={
            <ProtectedRoute
              children={
                <DevicesProvider>
                  <Trial />
                </DevicesProvider>
              }
            />
          }
        />
        <Route
          path={urls.login.path}
          element={<RedirectIfAuth children={<Login />} />}
        />
      </Routes>
    </BrowserRouter>
  );
}
