export function findUniqueFromArray(array1, array2) {
  // Extract all "device" values from the second array
  const deviceValues = array2.map((obj) => obj.device);

  // Use filter to keep objects in array1 that have unique "id" values
  const uniqueObjects = array1.filter((obj) => !deviceValues.includes(obj.id));

  return uniqueObjects;
}

export function findUniqueFromArrayWith(array1, array2, deviceId) {
  // Extract all "device" values from the second array
  const deviceValues = array2.map((obj) => obj.device);

  const currentDevice = array1.find((obj) => obj.id === deviceId);

  // Use filter to keep objects in array1 that have unique "id" values
  const uniqueObjects = array1.filter((obj) => !deviceValues.includes(obj.id));

  return [currentDevice, ...uniqueObjects];
}
